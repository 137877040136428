import styled from 'styled-components';

import { colors } from '~/lib/colors';

export const Headr = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2.4rem;
  background-color: ${colors.white};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 1.5px 3px 0px;

  & > svg {
    cursor: pointer;
  }
`;

export const HeaderLogo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9rem;
  width: 9rem;
`;

export const BetaTag = styled.p`
  position: absolute;
  top: 4px;
  right: 0rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  color: ${colors.error};
  font-family: cursive;
`;

export const HeaderEmailContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderEmailInitial = styled.div`
  background-color: #5a26b1;
  color: ${colors.white};
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  margin-right: 8px;
  text-transform: uppercase;
`;

export const HeaderEmailSvgContainer = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const FarmNameSpecieContainer = styled.div<{ sidebar?: boolean }>`
  margin-right: 2rem;
  text-transform: capitalize;
  width: fit-content;

  ${({ sidebar }) =>
    sidebar &&
    'position:fixed;top:5.5rem;left:5rem;margin-right:0;margin-left:2.2rem;'}
`;
