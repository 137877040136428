const ReactQueryKeys = {
  LOG_IN: 'LOG_IN',
  REGISTER_USER: 'REGISTER_USER',
  CHECK_USER_SESSION: 'CHECK_USER_SESSION',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  RESEND_VERIFICATION_EMAIL: 'RESEND_VERIFICATION_EMAIL',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  GET_SPECIES: 'GET_SPECIES',
  LOG_OUT: 'LOG_OUT',
  UPDATE_FARM_PROFILE: 'UPDATE_FARM_PROFILE',
  ADD_ANIMAL: 'ADD_ANIMAL',
  GET_ANIMALS: 'GET_ANIMALS',
  GET_ANIMAL: 'GET_ANIMAL',
  GET_FARM_PROFILE: 'GET_FARM_PROFILE',
  UPDATE_ANIMAL: 'UPDATE_ANIMAL',
  DELETE_ANIMAL: 'DELETE_ANIMAL',
  ADD_HERD_TAG: 'ADD_HERD_TAG',
  GET_HERD_TAGS: 'GET_HERD_TAGS',
  GET_HERD_TAG: 'GET_HERD_TAG',
  UPDATE_HERD_TAG: 'UPDATE_HERD_TAG',
  DELETE_HERD_TAG: 'DELETE_HERD_TAG',
  ADD_HERD_ALERT: 'ADD_HERD_ALERT',
  ADD_BIRTH: 'ADD_BIRTH',
  UPDATE_BIRTH: 'UPDATE_BIRTH',
  DELETE_BIRTH: 'DELETE_BIRTH',
  ADD_DEATH_DETAILS: 'ADD_DEATH_DETAILS',
  UPDATE_DEATH_DETAILS: 'UPDATE_DEATH_DETAILS',
  DELETE_DEATH_DETAILS: 'DELETE_DEATH_DETAILS',
  GET_INVENTORY_TYPES: 'GET_INVENTORY_TYPES',
  ADD_INVENTORY_ITEM: 'ADD_INVENTORY_ITEM',
  GET_INVENTORY_ITEMS: 'GET_INVENTORY_ITEMS',
  GET_INVENTORY_ITEM: 'GET_INVENTORY_ITEM',
  UPDATE_INVENTORY_ITEM: 'UPDATE_INVENTORY_ITEM',
  DELETE_INVENTORY_ITEM: 'DELETE_INVENTORY_ITEM',
  ADD_VACCINATION: 'ADD_VACCINATION',
  GET_VACCINATIONS: 'GET_VACCINATIONS',
  GET_VACCINATION: 'GET_VACCINATION',
  UPDATE_VACCINATION: 'UPDATE_VACCINATION',
  DELETE_VACCINATION: 'DELETE_VACCINATION',
  GET_DISEASES: 'GET_DISEASES',
  ADD_TREATMENT: 'ADD_TREATMENT',
  GET_TREATMENTS: 'GET_TREATMENTS',
  GET_TREATMENT: 'GET_TREATMENT',
  UPDATE_TREATMENT: 'UPDATE_TREATMENT',
  DELETE_TREATMENT: 'DELETE_TREATMENT',
  ADD_TEST: 'ADD_TEST',
  GET_TESTING_LABS: 'GET_TESTING_LABS',
  GET_TESTS: 'GET_TESTS',
  GET_TEST: 'GET_TEST',
  UPDATE_TEST: 'UPDATE_TEST',
  DELETE_TEST: 'DELETE_TEST',
  GET_BREEDING_TYPES: 'GET_BREEDING_TYPES',
  ADD_BREEDING: 'ADD_BREEDING',
  GET_BREEDINGS: 'GET_BREEDINGS',
  GET_BREEDING: 'GET_BREEDING',
  UPDATE_BREEDING: 'UPDATE_BREEDING',
  DELETE_BREEDING: 'DELETE_BREEDING',
  GET_ALIVE_ANIMALS: 'GET_ALIVE_ANIMALS',
  GET_BREEDS: 'GET_BREEDS',
  ADD_ANIMAL_BREED: 'ADD_ANIMAL_BREED',
  UPDATE_ANIMAL_BREED: 'ADD_ANIMAL_BREED',
  DELETE_ANIMAL_BREED: 'ADD_ANIMAL_BREED',
  ADD_PASTURE: 'ADD_PASTURE',
  GET_PASTURES: 'GET_PASTURES',
  GET_PASTURE: 'GET_PASTURE',
  UPDATE_PASTURE: 'UPDATE_PASTURE',
  DELETE_PASTURE: 'DELETE_PASTURE',
  ADD_PASTURE_ASSIGNMENT: 'ADD_PASTURE_ASSIGNMENT',
  GET_PASTURE_ASSIGNMENTS: 'GET_PASTURE_ASSIGNMENTS',
  GET_PASTURE_ASSIGNMENT: 'GET_PASTURE_ASSIGNMENT',
  UPDATE_PASTURE_ASSIGNMENT: 'UPDATE_PASTURE_ASSIGNMENT',
  DELETE_PASTURE_ASSIGNMENT: 'DELETE_PASTURE_ASSIGNMENT',
  GET_HERD_ALERTS: 'GET_HERD_ALERTS',
  GET_HERD_ALERT: 'GET_HERD_ALERT',
  UPDATE_HERD_ALERT: 'UPDATE_HERD_ALERT',
  DELETE_HERD_ALERT: 'DELETE_HERD_ALERT',
  ADD_FARM_ALERT: 'ADD_FARM_ALERT',
  GET_FARM_ALERTS: 'GET_FARM_ALERTS',
  GET_FARM_ALERT: 'GET_FARM_ALERT',
  UPDATE_FARM_ALERT: 'UPDATE_FARM_ALERT',
  DELETE_FARM_ALERT: 'DELETE_FARM_ALERT',
  ADD_ANIMAL_WEIGHT: 'ADD_ANIMAL_WEIGHT',
  UPDATE_ANIMAL_WEIGHT: 'UPDATE_ANIMAL_WEIGHT',
  DELETE_ANIMAL_WEIGHT: 'DELETE_ANIMAL_WEIGHT',
  ADD_ANIMAL_TRAIT: 'ADD_ANIMAL_TRAIT',
  GET_TRAITS: 'GET_TRAITS',
  UPDATE_ANIMAL_TRAIT: 'UPDATE_ANIMAL_TRAIT',
  DELETE_ANIMAL_TRAIT: 'UPDATE_ANIMAL_TRAIT',
  ADD_MAINTENANCE_RECORD: 'ADD_MAINTENANCE_RECORD',
  GET_MAINTENANCE_RECORDS: 'GET_MAINTENANCE_RECORDS',
  UPDATE_MAINTENANCE_RECORD: 'UPDATE_MAINTENANCE_RECORD',
  DELETE_MAINTENANCE_RECORD: 'DELETE_MAINTENANCE_RECORD',
  ADD_ANIMAL_SALE: 'ADD_ANIMAL_SALE',
  GET_ANIMAL_SALES: 'GET_ANIMAL_SALES',
  GET_BUYERS: 'GET_BUYERS',
  GET_BUYER_TYPES: 'GET_BUYER_TYPES',
  GET_ANIMAL_SALE: 'GET_ANIMAL_SALE',
  UPDATE_ANIMAL_SALE: 'UPDATE_ANIMAL_SALE',
  DELETE_ANIMAL_SALE: 'DELETE_ANIMAL_SALE',
  ADD_FARM_ITEM_SALE: 'ADD_FARM_ITEM_SALE',
  GET_FARM_ITEM_SALES: 'GET_FARM_ITEM_SALES',
  GET_FARM_ITEM_SALE: 'GET_FARM_ITEM_SALE',
  UPDATE_FARM_ITEM_SALE: 'UPDATE_FARM_ITEM_SALE',
  DELETE_FARM_ITEM_SALE: 'DELETE_FARM_ITEM_SALE',
  UPLOAD_ANIMAL_IMAGE: 'UPLOAD_ANIMAL_IMAGE',
  DELETE_ANIMAL_IMAGE: 'UPLOAD_ANIMAL_IMAGE',
  UPLOAD_INVENTORY_ITEM_IMAGE: 'UPLOAD_INVENTORY_ITEM_IMAGE',
  DELETE_INVENTORY_ITEM_IMAGE: 'DELETE_INVENTORY_ITEM_IMAGE',
  UPLOAD_ANIMAL_SALE_FILE: 'UPLOAD_ANIMAL_SALE_FILE',
  DELETE_ANIMAL_SALE_FILE: 'DELETE_ANIMAL_SALE_FILE',
  UPLOAD_FARM_ITEM_SALE_FILE: 'UPLOAD_FARM_ITEM_SALE_FILE',
  DELETE_FARM_ITEM_SALE_FILE: 'DELETE_FARM_ITEM_SALE_FILE',
  UPLOAD_TEST_RESULT_FILE: 'UPLOAD_TEST_RESULT_FILE',
  DELETE_TEST_RESULT_FILE: 'DELETE_TEST_RESULT_FILE',
  UPLOAD_MAINTENANCE_RECORD_FILE: 'UPLOAD_MAINTENANCE_RECORD_FILE',
  GET_MAINTENANCE_RECORD: 'GET_MAINTENANCE_RECORD',
  DELETE_MAINTENANCE_RECORD_FILE: 'DELETE_MAINTENANCE_RECORD_FILE',
  CREATE_FARM_ACCESS_TOKEN: 'CREATE_FARM_ACCESS_TOKEN',
  GET_ANIMAL_PEDIGREE_INFORMATION: 'GET_ANIMAL_PEDIGREE_INFORMATION',
  UPDATE_USER_EMAIL: 'UPDATE_USER_EMAIL',
  GET_ORGANIZATIONS: 'GET_ORGANIZATIONS',
  GET_METRICS: 'GET_METRICS',
  GET_MEASURES: 'GET_MEASURES',
  ADD_ANIMAL_METRIC: 'ADD_ANIMAL_METRIC',
  UPDATE_ANIMAL_METRIC: 'UPDATE_ANIMAL_METRIC',
  DELETE_ANIMAL_METRIC: 'DELETE_ANIMAL_METRIC',
  BULK_ADD_ANIMALS: 'BULK_ADD_ANIMALS',
  BULK_UPDATE_ANIMALS: 'BULK_UPDATE_ANIMALS',
  GET_ALL_ANIMAL_IDS: 'GET_ALL_ANIMAL_IDS',
  UPDATE_USER_PLAN: 'UPDATE_USER_PLAN',
  RELOAD_USER_SUBSCRIPTION: 'RELOAD_USER_SUBSCRIPTION',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
};

export default ReactQueryKeys;
