const alerts = () => '/alerts';

const farmAlerts = () => '/alerts/farm';

const farmAlert = (id: string | number) => `${farmAlerts()}/${id}`;

const herdAlerts = () => '/alerts/herd';

const herdAlert = (id: string | number) => `${herdAlerts()}/${id}`;

const animals = () => '/animals';

const animal = (id: string | number) => `${animals()}/${id}`;

const birthDetails = (animalId: string | number, birthId: string | number) =>
  `${animal(animalId)}/birth-details/${birthId}`;

const pedigree = (animalId: string | number) => `${animal(animalId)}/pedigree`;

const activateEmail = (token: string) => `/auth/activate/email/${token}`;

const resetPassword = (uid: string, token: string) =>
  `/auth/password/${uid}/${token}`;

const breedings = () => '/breedings';

const breeding = (id: string | number) => `${breedings()}/${id}`;

const care = () => '/care';

const tests = () => '/care/tests';

const test = (id: string | number) => `${tests()}/${id}`;

const treatments = () => '/care/treatments';

const treatment = (id: string | number) => `${treatments()}/${id}`;

const vaccinations = () => '/care/vaccinations';

const vaccination = (id: string | number) => `${vaccinations()}/${id}`;

const herds = () => '/herds';

const herdTags = () => `${herds()}/tags`;

const herdTag = (id: string | number) => `${herdTags()}/${id}`;

const maintenanceRecords = () => '/inventory/maintenance-records';

const maintenanceRecord = (id: string | number) =>
  `${maintenanceRecords()}/${id}`;

const inventoryItems = () => '/inventory';

const inventoryItem = (id: string | number) => `${inventoryItems()}/${id}`;

const pastureAssignments = () => '/pastures/assignments';

const pastureAssignment = (id: string | number) =>
  `${pastureAssignments()}/${id}`;

const pastures = () => '/pastures';

const pasture = (id: string | number) => `${pastures()}/${id}`;

const sales = () => '/sales';

const animalSales = () => '/sales/animals';

const animalSale = (id: string | number) => `${animalSales()}/${id}`;

const farmItemSales = () => '/sales/farm-items';

const farmItemSale = (id: string | number) => `${farmItemSales()}/${id}`;

const createAccount = () => '/create-account';

const forgotPassword = () => '/forgot-password';

const login = () => '/log-in';

const profile = () => '/profile';

const resendVerificationEmail = () => '/resend-verification-email';

const subscriptions = () => '/subscriptions';

const metrics = (id: string) => animal(id) + '/metrics';

const weights = (id: string) => animal(id) + '/weights';

const traits = (id: string) => animal(id) + '/traits';

const routes = {
  activateEmail,
  alerts,
  animal,
  animals,
  animalSale,
  animalSales,
  birthDetails,
  breeding,
  breedings,
  care,
  createAccount,
  farmAlert,
  farmAlerts,
  farmItemSale,
  farmItemSales,
  forgotPassword,
  herdAlert,
  herdAlerts,
  herds,
  herdTag,
  herdTags,
  inventoryItem,
  inventoryItems,
  login,
  maintenanceRecord,
  maintenanceRecords,
  pasture,
  pastures,
  pastureAssignment,
  pastureAssignments,
  pedigree,
  profile,
  resendVerificationEmail,
  resetPassword,
  sales,
  test,
  tests,
  treatment,
  treatments,
  vaccination,
  vaccinations,
  subscriptions,
  metrics,
  weights,
  traits,
};

export default routes;
