import axios from 'axios';
import queryString from 'query-string';

import type { AxiosInstance } from 'axios';
import type { GetAllReturn } from '../types/get-all-return.type';

import { getItem, setItem, StorageKeys } from '../utils/localStorage';

import { refreshToken } from './mutations/useRefreshToken';

import useStore from '../store';

const TOKEN_ENDPOINT = '/token/refresh/';

export const apiV1 = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

const getErrorMessageFromResponse = (
  data: Record<string, Record<'code' | 'message', string>>
) => {
  if (!data) return;

  let message = 'Something went wrong';

  const firstErrorKey = Object.keys(data)[0];

  const firstErrorValue = data?.[firstErrorKey]?.message;

  message = `${firstErrorKey?.replace(/_/g, ' ')}: ${firstErrorValue}`;

  if (data?.detail) {
    message = data.detail?.message as string;

    if (data.detail?.code === 'token_not_valid') {
      message = 'Your session has expired. Please log in again.';
    }
  }

  if (data?.['non_field_errors']) {
    message = data?.['non_field_errors']?.message as string;
  }

  return message;
};

apiV1.interceptors.request.use(function (config) {
  let headers = config.headers;

  const excludedRoutes = [
    '/login/',
    '/password/reset/',
    '/password/reset/confirm/',
    '/register/',
    '/register/resend-email/',
    '/verify-email/',
    '/token/verify/',
    '/species/',
    '/emails/',
    TOKEN_ENDPOINT,
  ];

  const accessToken = getItem(StorageKeys.ACCESS_TOKEN);

  const addAuthHeader =
    accessToken &&
    excludedRoutes.every((route) => !config.url?.startsWith(route)) &&
    !config.url?.includes('/pedigree/?token=');

  if (addAuthHeader) {
    headers = { ...headers, Authorization: `Bearer ${accessToken}` };
  }

  config.headers = headers;

  return config;
});

apiV1.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const status = error?.response?.status;

    const data = error?.response?.data ?? {};

    const originalRequest = error.config;

    const originalUrl = originalRequest.url;

    if (
      status === 401 &&
      data?.detail?.code === 'token_not_valid' &&
      !originalRequest?._retry &&
      !originalUrl?.includes(TOKEN_ENDPOINT)
    ) {
      return refreshToken()
        .then((data) => {
          originalRequest._retry = true;

          if (data) {
            setItem(StorageKeys.ACCESS_TOKEN, data.access);

            setItem(StorageKeys.REFRESH_TOKEN, data.refresh);

            originalRequest.headers['Authorization'] = `Bearer ${data.access}`;
          }

          return apiV1(originalRequest);
        })
        .catch((error) => {
          const updateUser = useStore.getState().updateUser;

          updateUser(null);

          data.message = getErrorMessageFromResponse(data);

          error.response.data = data;

          return Promise.reject(error);
        });
    }

    data.message = getErrorMessageFromResponse(data);

    (error.response ?? {}).data = data;

    return Promise.reject(error);
  }
);

export function getAll<T>(axiosInstance: AxiosInstance, url: string) {
  return async (
    page: number,
    limit: number,
    filters?: Record<string, unknown>,
    signal?: AbortSignal
  ): Promise<GetAllReturn<T>> => {
    const query = { limit, offset: (page - 1) * limit, ...filters };

    const q = queryString.stringify(query);

    const response = await axiosInstance.get(`${url}?${q}`, {
      signal: signal ?? undefined,
    });

    return response.data;
  };
}

const checkUrlLastChar = (url: string) => {
  return (url = url.endsWith('/') ? url : `${url}/`);
};

export function getOne<T>(axiosInstance: AxiosInstance, url: string) {
  url = checkUrlLastChar(url);

  return async (id: string): Promise<T> => {
    const response = await axiosInstance.get(`${url}${id}/`);

    return response.data;
  };
}

export function createOne<DT, RT>(axiosInstance: AxiosInstance, url: string) {
  return async (data: DT): Promise<RT> => {
    const response = await axiosInstance.post(url, data);

    return response.data;
  };
}

export function updateOne<DT, RT>(axiosInstance: AxiosInstance, url: string) {
  url = checkUrlLastChar(url);

  return async (id: string, data: DT): Promise<RT> => {
    const response = await axiosInstance.patch(`${url}${id}/`, data);

    return response.data;
  };
}

export function deleteOne<T>(axiosInstance: AxiosInstance, url: string) {
  url = checkUrlLastChar(url);

  return async (id: string): Promise<T> => {
    const response = await axiosInstance.delete(`${url}${id}/`);

    return response.data;
  };
}
