import create, { SetState } from 'zustand';
import { devtools } from 'zustand/middleware';

import userSlice, { IUserSlice } from './slices/user.slice';

export type StoreSlice<T> = (set: SetState<IUserSlice>) => T;

type StoreState = IUserSlice;

const useStore = create<StoreState>(
  devtools((set) => ({
    ...userSlice(set),
  }))
);

export default useStore;
