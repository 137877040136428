/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreSlice } from '..';

import type { User } from '../../types/user.type';

export interface IUserSlice {
  currentUser: User | null;
  updateUser: (user: IUserSlice['currentUser']) => void;
}

const userSlice: StoreSlice<IUserSlice> = (set) => ({
  currentUser: null,
  updateUser: (user) => set({ currentUser: user }),
});

export default userSlice;
