import { useMutation } from 'react-query';

import ReactQueryKeys from '../keys';
import { apiV1 } from '../utils';

import { getItem, setItem, StorageKeys } from '../../utils/localStorage';

type RefreshTokenData = {
  access: string;
  refresh: string;
};

export const refreshToken = async (): Promise<RefreshTokenData | null> => {
  const refreshToken = getItem(StorageKeys.REFRESH_TOKEN);

  if (!refreshToken) return Promise.resolve(null);

  const response = await apiV1.post('/token/refresh/', {
    refresh: refreshToken,
  });

  return response.data;
};

const useRefreshToken = () => {
  return useMutation(refreshToken, {
    mutationKey: ReactQueryKeys.REFRESH_TOKEN,
    onSuccess: (data) => {
      if (data) {
        setItem(StorageKeys.ACCESS_TOKEN, data.access);

        setItem(StorageKeys.REFRESH_TOKEN, data.refresh);
      }
    },
    retry: 1,
  });
};

export default useRefreshToken;
