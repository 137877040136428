export const StorageKeys = {
  ACCESS_TOKEN: 'pt',
  REFRESH_TOKEN: 'ct',
};

export const setItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getItem = (key: string) => {
  return localStorage.getItem(key);
};

export const removeItem = (key: string) => {
  localStorage.removeItem(key);
};

export const clearItems = () => {
  localStorage.removeItem(StorageKeys.ACCESS_TOKEN);

  localStorage.removeItem(StorageKeys.REFRESH_TOKEN);
};
